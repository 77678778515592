import React from 'react';
import { get, map } from 'lodash/fp';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Accordion } from '../../components/Accordion/Accordion';
import { AccordionItem } from '../../components/Accordion/AccordionItem';
import { ContainerRoot } from '../../components/Container/styles';
import { Conditional } from '../../components/Conditional/Conditional';
import { SubHeading } from '../../components/SubHeading/SubHeading';

export const AccordionBlock = props => (
  <ContainerRoot
    as="section"
    data-litium-block-id={get('draftId', props)}
    noPadding={get('inline', props)}
    displayOnMobile={props.displayOnMobile}
    displayOnDesktop={props.displayOnDesktop}
  >
    <AccordionBlockContent centerFaq={get('centerFaq', props)}>
      <Conditional show={!!get('heading', props)}>
        <SubHeading noUnderline centerMobile as={get('hasFirstHeading', props) ? 'h1' : 'h2'}>
          {get('heading', props)}
        </SubHeading>
      </Conditional>
      <Accordion>
        {map(
          item => (
            <AccordionItem htmlContent key={item.question} title={get('question', item)}>
              <div>{get('answer', item)}</div>
            </AccordionItem>
          ),
          get('questionAndAnswer', props)
        )}
      </Accordion>
    </AccordionBlockContent>
  </ContainerRoot>
);

const AccordionBlockContent = styled.div`
  max-width: 750px;
  margin: 75px ${props => (props.centerFaq ? 'auto' : 0)};
`;

AccordionBlock.defaultProps = {
  displayOnMobile: true,
  displayOnDesktop: true
};

AccordionBlock.propTypes = {
  displayOnMobile: PropTypes.bool,
  displayOnDesktop: PropTypes.bool
};
