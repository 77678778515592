import PropTypes from 'prop-types';
import React, { PureComponent, cloneElement } from 'react';
import { get } from 'lodash/fp';
import { animateScroll } from 'react-scroll';
import { AccordionContainer } from './styles';

export class Accordion extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: null
    };
    this.accordionRef = React.createRef();
    this.setActive = this.setActive.bind(this);
  }

  componentDidMount() {
    const { children } = this.props;

    if (Array.isArray(children)) {
      children.forEach((item, index) => {
        if (get(['props', 'activeOnLoad'], item)) {
          this.setActive(index);
        }
      });
    } else if (get(['props', 'activeOnLoad'], children)) {
      this.setActive(0);
    }
  }

  setActive(index) {
    const showItem = this.state.activeIndex !== index;
    const startPos = this.accordionRef.current ? this.accordionRef.current.offsetTop - 80 : 0;
    const heightOfItem = 56;
    const newPos = startPos + index * heightOfItem;
    const shouldViewScroll = showItem && window.pageYOffset > newPos;

    if (shouldViewScroll) {
      animateScroll.scrollTo(newPos, {
        duration: 300,
        smooth: true
      });
    }

    this.setState(prevState => ({ activeIndex: prevState.activeIndex === index ? null : index }));
  }

  render() {
    const { tm, bm, children, childrenAsH2 } = this.props;

    if (!Array.isArray(children)) {
      return (
        <AccordionContainer tm={tm} bm={bm} ref={this.accordionRef}>
          {cloneElement(children, {
            key: 0,
            index: 0,
            childrenAsH2,
            setActive: this.setActive,
            isActive: this.state.activeIndex === 0,
            isLastItem: true
          })}
        </AccordionContainer>
      );
    }

    return (
      <AccordionContainer tm={tm} bm={bm} ref={this.accordionRef}>
        {children.map((item, index) => {
          if (!item) return false;

          return cloneElement(item, {
            key: index,
            index,
            childrenAsH2,
            setActive: this.setActive,
            isActive: this.state.activeIndex === index,
            isLastItem: children.length === index + 1
          });
        })}
      </AccordionContainer>
    );
  }
}

Accordion.defaultProps = {
  childrenAsH2: false,
  tm: '30px',
  bm: '0px'
};

Accordion.propTypes = {
  childrenAsH2: PropTypes.bool,
  tm: PropTypes.string,
  bm: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired
};
