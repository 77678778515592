import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fonts } from '../../styles/typography';
import { HtmlContainer } from './HtmlWrapper';

export class AccordionHtmlWrapper extends React.Component {
  render() {
    const { html, bodyFontSize, widerMaxWidthSet, htmlIn } = this.props;
    const getHtml = () => ({ __html: html });
    return (
      <AccordionHtmlContainer
        widerMaxWidthSet={widerMaxWidthSet}
        bodyFontSize={bodyFontSize}
        dangerouslySetInnerHTML={getHtml()}
        htmlIn={htmlIn}
      />
    );
  }
}

const AccordionHtmlContainer = styled(HtmlContainer)`
  h3,
  .h3 {
    ${fonts.NeoSansLight};
    font-size: 14px !important;
    font-weight: 300 !important;

    &:first-child {
      margin-top: 0;
    }
  }
`;

AccordionHtmlWrapper.defaultProps = {
  bodyFontSize: 18,
  widerMaxWidthSet: null,
  htmlIn: false
};

AccordionHtmlWrapper.propTypes = {
  html: PropTypes.string.isRequired,
  bodyFontSize: PropTypes.number,
  widerMaxWidthSet: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  htmlIn: PropTypes.bool
};
