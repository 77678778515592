import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import AnimateHeight from 'react-animate-height';
import { AccordionHtmlWrapper } from '../HtmlWrapper/AccordionHtmlWrapper';

import {
  AccordionItemTitle,
  AccordionItemButton,
  AccordionItemContainer,
  Arrow,
} from './styles';

export class AccordionItem extends PureComponent {
  render() {
    const {
      index,
      isActive,
      isLastItem,
      children,
      title,
      setActive,
      htmlContent,
      bgColorWhite,
      childrenAsH2,
      onClickCallback,
    } = this.props;

    return (
      <AccordionItemContainer
        active={isActive}
        lastItem={isLastItem}
        bgColorWhite={bgColorWhite}
      >
        <AccordionItemButton
          active={isActive}
          lastItem={isLastItem}
          onClick={() => {
            setActive(index);
            if (onClickCallback) {
              onClickCallback(!isActive);
            }
          }}
        >
          <AccordionItemTitle as={childrenAsH2 ? 'h2' : 'p'}>
            {title}
          </AccordionItemTitle>
          <Arrow active={isActive} />
        </AccordionItemButton>
        <AnimateHeight
          className="animation"
          duration={300}
          delay={0}
          height={isActive ? 'auto' : 0}
        >
          {htmlContent ? (
            <AccordionHtmlWrapper
              htmlIn
              bodyFontSize={16}
              html={get(['props', 'children'], children)}
            />
          ) : (
            children
          )}
        </AnimateHeight>
      </AccordionItemContainer>
    );
  }
}

AccordionItem.defaultProps = {
  children: null,
  index: 0,
  childrenAsH2: false,
  bgColorWhite: false,
  isActive: false,
  isLastItem: false,
  setActive: null,
  htmlContent: false,
  onClickCallback: null,
};

AccordionItem.propTypes = {
  index: PropTypes.number,
  childrenAsH2: PropTypes.bool,
  bgColorWhite: PropTypes.bool,
  isActive: PropTypes.bool,
  isLastItem: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  setActive: PropTypes.func,
  htmlContent: PropTypes.bool,
  onClickCallback: PropTypes.func,
};
