import styled, { css } from 'styled-components';
import { color, thresholds } from '../../styles/variables';
import { hover, media } from '../../styles/mixins';
import { fonts } from '../../styles/typography';

export const AccordionContainer = styled.div`
  width: 100%;

  margin: 30px 0 0;
  ${props =>
    props.tm &&
    css`
      margin-top: ${props.tm};
    `};
  ${props =>
    props.bm &&
    css`
      margin-bottom: ${props.bm};
    `};
`;

export const AccordionItemContainer = styled.div`
  width: 100%;
  > .animation > div {
    > * {
      margin: 0;
      padding-top: 12px !important;
      padding-bottom: 25px !important;
    }
    ${props =>
      props.active &&
      css`
        background-color: ${color.lightGrey};
      `};
  }
  > .animation > div > * {
    padding: 0 20px;
    font-size: 16px;
    &:not(button) {
      padding-bottom: 20px;
    }
    ${media.S`
      font-size: 16px;
    `};
  }
  ${props =>
    props.active &&
    props.lastItem &&
    css`
      border-bottom: solid 1px ${color.grey};
    `};

  ${props =>
    props.bgColorWhite &&
    css`
      > button,
      > div > div {
        background-color: ${props.theme.color.white} !important;
      }
    `};
`;

export const AccordionItemWhiteContainer = styled.div`
  width: 100%;
  background: ${props => props.theme.color.white};
  border: solid 1px ${color.grey};
  > .animation > div {
    > * {
      margin: 0;
      padding-top: 12px !important;
      padding-bottom: 25px !important;
    }
  }
`;

export const AccordionItemTitle = styled.p`
  flex: 1;
  padding: 0 26px 0 20px;
  margin: 17px 0;

  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  ${fonts.NeoSansRegular};
  ${media.S`
    font-size: 14px;
  `};

  ${props =>
    props.disabled &&
    css`
      opacity: ${thresholds.disabledOpacity};
    `};

  span {
    ${fonts.NeoSansLight};
    font-size: 14px;
    margin-left: 5px;
  }
`;

export const Arrow = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  width: 40px;
  height: 40px;
  pointer-events: none;
  &::before,
  &::after {
    display: block;
    content: '';
    width: 10px;
    height: 1px;
    top: 50%;
    background: ${color.black};
    position: absolute;
  }

  &::before {
    transform: rotate(-90deg);
    right: 15px;
  }

  &::after {
    transform: rotate(0deg);
    left: 15px;
  }

  ${props =>
    props.active &&
    css`
      &::before {
        transform: rotate(0deg);
      }
      &::after {
      }
    `};
  ${props =>
    props.disabled &&
    css`
      opacity: ${thresholds.disabledOpacity};
    `};
`;

export const AccordionItemButton = styled.button`
  position: relative;
  width: 100%;
  padding: 0 1rem 0 0;
  outline: none;
  cursor: pointer;
  border: solid 1px ${color.grey};
  border-left: 0;
  border-right: 0;
  border-bottom-color: ${props => (props.active || props.lastItem ? color.grey : 'transparent')};
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  text-align: left;
  color: ${color.black};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10px;
  background-color: transparent;

  p {
    transition: color 0.3s ease-out;
    color: ${color.black};
  }
  span {
  }
  span:before,
  span:after {
    background: ${color.black};
    transition: transform 0.3s ease-out, background-color 0.3s ease-out;
  }

  ${media.S`
    font-size: 12px;
  `};

  ${props =>
    props.active &&
    css`
      background-color: ${color.lightGrey};
    `};

  transition: background-color 0.3s ease-out;

  ${hover`
    background-color: ${color.lightGrey}; 
  `};

  ${props =>
    props.active &&
    css`
      border-bottom: none;
    `};
`;

export const AccordionItemWhiteButton = styled.button`
  position: relative;
  width: 100%;
  padding: 0 1rem 0 0;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  text-align: left;
  color: ${color.black};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10px;
  background: transparent;
  border: 0;

  p {
    transition: color 0.3s ease-out;
    color: ${color.black};
  }

  span {
  }
  span:before,
  span:after {
    background: ${color.black};
    transition: transform 0.3s ease-out, background-color 0.3s ease-out;
  }

  ${media.S`
    font-size: 12px;
  `};

  transition: background-color 0.3s ease-out;
`;
