import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { media } from '../../styles/mixins';
import { HtmlWrapper } from '../../components/HtmlWrapper/HtmlWrapper';
import { ContainerRoot } from '../../components/Container/styles';

export const EditorBlock = props => {
  const { draftId, body, inline, displayOnMobile, displayOnDesktop } = props;

  if (!body) return false;

  return (
    <EditorBlockRoot
      as="section"
      data-litium-block-id={draftId}
      noPadding={inline}
      displayOnMobile={displayOnMobile}
      displayOnDesktop={displayOnDesktop}
    >
      <HtmlWrapper html={body} />
    </EditorBlockRoot>
  );
};

EditorBlock.defaultProps = {
  draftId: undefined,
  body: undefined,
  inline: false,
  displayOnMobile: true,
  displayOnDesktop: true
};

EditorBlock.propTypes = {
  draftId: PropTypes.string,
  body: PropTypes.string,
  inline: PropTypes.bool,
  displayOnMobile: PropTypes.bool,
  displayOnDesktop: PropTypes.bool
};

const EditorBlockRoot = styled(ContainerRoot)`
  margin: 40px auto;
  max-width: 903px;
  box-sizing: content-box;
  ${media.M`
    margin: 60px auto;
  `};
`;
